import React, { useContext, useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import Pager from '../components/utils/pager';
import { PATH } from '../helper/config';
import { Helmet } from 'react-helmet';
import '../css/news_list.css';
import { AppContext } from '../context/context';
import Util from './utils/util';

const NewsList: React.FunctionComponent<any> = ({ data, pageContext }) => {
  const context = useContext(AppContext);
  const localData = data.allWpGqlNewsItem.edges;
  let localePath: string = '';
  if (context.locale === 'en_US') localePath = '/en';

  useEffect(() => {
    context.setLanguage(
      {
        ja: `${PATH.NEWS}`,
        en: `/en${PATH.NEWS}`
      },
      pageContext.locale
    );
  }, []);

  return (
    <div className="news-list util__page">
      <Helmet>
        <title>NEWS | POST-FAKE</title>
        <meta property="og:title" content={`NEWS | POST-FAKE`} />
        <meta name="twitter:site" content={`NEWS | POST-FAKE`} />
        <meta name="twitter:title" content={`NEWS | POST-FAKE`} />
        <meta property="og:url" content={`https://postfake.com${context.localePath}${PATH.NEWS}`} />
      </Helmet>
      <nav className="news-list__sorter">
        <ul className="news-list__sorter-list">
          <li className={`news-list__sorter-list-item link__alpha active ff-mono`}>ALL</li>

          {pageContext.categories &&
            pageContext.categories.map((elem: any, i: number) => {
              if (elem.node.slug !== 'exhibition') {
                return (
                  <li className={`news-list__sorter-list-item link__alpha ff-mono`} key={`news-list__sorter-list-item-${i}`}>
                    <Link to={`${localePath}/news/${elem.node.slug}`}>{elem.node.name}</Link>
                  </li>
                );
              }
            })}
        </ul>
      </nav>
      <ul className="news-list__list">
        {localData.map((elem: any, i: number) => {
          return (
            <li className="news-list__list-item" key={`news-list__list-item-${i}`}>
              <Link to={`${localePath}${PATH.NEWS_DETAIL}${elem.node.databaseId}`}>
                <span className="news-list__list-item-date ff-mono">{Util.GET_DATE(elem.node.date).ja}</span>
                <span className="news-list__list-item-title">{elem.node.title}</span>
                <span className="news-list__list-item-category ff-mono">
                  {elem.node.gqlNewsCategories.nodes &&
                    elem.node.gqlNewsCategories.nodes.map((category: any, k: number) => {
                      return <span key={`news-list-item-category-${i}-${k}`}>{category.name}</span>;
                    })}
                </span>
                <i className="glyphs-arrow-right"></i>
              </Link>
            </li>
          );
        })}
      </ul>
      <Pager context={pageContext} basePath={PATH.NEWS} path={PATH.NEWS_PAGE} />
    </div>
  );
};
export default NewsList;

export const query = graphql`
  query newsPageQuery($skip: Int!, $limit: Int!, $locale: String!) {
    allWpGqlNewsItem(skip: $skip, limit: $limit, filter: { locale: { locale: { eq: $locale } } }, sort: { fields: date, order: DESC }) {
      edges {
        node {
          databaseId
          date
          title
          gqlNewsCategories {
            nodes {
              slug
              name
            }
          }
        }
      }
    }
  }
`;
